// @flow

import React from "react";
import { DashboardLayout, Layout, withPrivateRoute } from "@containers";
import Assignments from "@components/Shared/Assignments";
/**
 * Home Page
 */
const TeamAssignments = (props: *) => (
    <Layout {...props}>
        <DashboardLayout assignmentsType="team" title="Alle opdrachten">
            <Assignments assignmentsType="team" location={props.location} />
        </DashboardLayout>
    </Layout>
);

export default withPrivateRoute(TeamAssignments);
